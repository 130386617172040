define("ember-adminlte-theme/components/main-header", ["exports", "ember"], function (exports, _ember) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      $ = _ember.default.$;
  exports.default = Component.extend({
    classNames: ["main-header"],
    tagName: "header",
    actions: {
      sidebarTogger: function sidebarTogger() {
        var class_name = 'control-sidebar-open';
        if ($('body').hasClass(class_name)) {
          $('body').addClass(class_name);
        } else {
          $('body').removeClass(class_name);
        }
      }
    }
  });
});