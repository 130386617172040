define('emberfire-utils/initializers/firebase-flex', ['exports', 'emberfire-utils/serializers/firebase-flex'], function (exports, _firebaseFlex) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;


  /**
   * Firebase Flex initializer
   *
   * @param {Object} application
   */
  function initialize(application) {
    application.register('serializer:-firebase-flex', _firebaseFlex.default);
  } /** @module emberfire-utils */
  exports.default = {
    name: 'firebase-flex',
    initialize: initialize
  };
});