define('ember-adminlte-theme/components/nav-breadcrumb', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed,
      isBlank = _ember.default.isBlank,
      isPresent = _ember.default.isPresent;
  exports.default = _ember.default.Component.extend({
    breadCrumb: [],

    routeName: computed("appRoute.controller.currentRouteName", function () {
      return this.get('appRoute.controller.currentRouteName');
    }),

    routeUrl: computed("appRoute.router.url", function () {
      return this.get("appRoute.router.url").replace(/\?.*/, "");
    }),

    // urlChanged: function(){
    //   this.breadCrumbComputed()
    // }.observes("appRoute.router.url"),

    urlChanged: function () {
      this.breadCrumbComputed();
    }.observes("appRoute.controller.currentRouteName"),

    didInsertElement: function didInsertElement() {
      var that = this;

      _ember.default.run.next(function () {
        that.breadCrumbComputed();
      });
    },
    defaultRouteTitle: function defaultRouteTitle() {
      return {
        "orders": "Orders",
        "orders.report": "OrderReport"
      };
    },
    initRouter: function initRouter() {
      var _name = this.get("appRoute.controller.currentRouteName");
      var _url = this.get("appRoute.router.url");
      this.set("routeName", _name);
      this.set("routeUrl", _url);
    },
    getRouterDefined: function getRouterDefined(_routeName) {
      if (isBlank(_routeName)) {
        return null;
      }
      // Ember 2.3 version
      // let container = Em.getOwner(this);
      return this.container.lookup('route:' + _routeName);
    },


    breadCrumbComputed: function breadCrumbComputed() {
      var that = this;
      var isRouterDefined = function isRouterDefined(_router) {
        return Em.isPresent(_router);
      };

      var urlNames = that.get("routeName").split(".");

      if (isBlank(urlNames[0])) {
        urlNames.shift();
      }

      var len = urlNames.length;
      var _routerArray = urlNames.map(function (name, index) {
        var exist = false;
        var _names = urlNames.slice(0, index + 1);
        var _routeName = _names.join(".");

        // 获取有效路由
        var router = that.getRouterDefined(_routeName);
        if (isRouterDefined(router)) {
          exist = true;
        } else {
          if (_names[len - 1] == "index") {
            _names.pop();
          } else {
            _names.push("index");
          }
        }

        _routeName = _names.join(".");
        router = that.getRouterDefined(_routeName);
        if (isRouterDefined(router)) {
          exist = true;
        }
        if (exist) {
          // 当路由有效时
          var navTitle = _names[_names.length - 1] || "";
          // 面包屑字面
          if (router.get("breadCrumb")) {
            navTitle = router.get("breadCrumb.title");
          } else {
            var tmpNames = _names.copy(),
                tmpRouteName = void 0,
                tmpRoute = void 0;
            var nameIndex = tmpNames.indexOf("index");
            nameIndex != -1 ? delete tmpNames[nameIndex] : tmpNames.push("index");

            tmpRouteName = tmpNames.join(".");
            tmpRoute = that.getRouterDefined(tmpRouteName);

            if (tmpRoute && tmpRoute.get("breadCrumb")) {
              navTitle = tmpRoute.get("breadCrumb.title");
            } else {
              navTitle = that.defaultRouteTitle()[_routeName];
            }
          }
          navTitle = navTitle || "";
          _names.unshift("#");
          return {
            title: navTitle,
            url: _names.join("/"),
            isActive: index == len - 1 ? true : false
          };
        } else {
          // 当路由无效时
          console.info('========> no router defined ' + _routeName);
        }
      }).compact();

      // if(_routerArray.length <= 0) 
      var lastIndex = _routerArray.length - 1,
          lastPre = _routerArray[lastIndex - 1],
          lastItem = _routerArray[lastIndex];

      if (lastItem.title == "index") {
        delete _routerArray[lastIndex];
        if (lastPre) {
          lastPre.isActive = true;
        }
      };

      if (/.*?\/index$/.test(lastItem.url)) {
        if (isPresent(_routerArray[lastIndex])) {
          delete _routerArray[lastIndex - 1];
        }
      }

      _routerArray.unshift({ title: "首页", url: "/", isActive: false });
      _routerArray = _routerArray.compact();

      that.set("breadCrumb", _routerArray);
    }
  });
});