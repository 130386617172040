define('ember-light-table/components/lt-foot', ['exports', 'ember', 'ember-light-table/templates/components/lt-foot', 'ember-light-table/mixins/table-header'], function (exports, _ember, _ltFoot, _tableHeader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      get = _ember.default.get,
      trySet = _ember.default.trySet;
  exports.default = Component.extend(_tableHeader.default, {
    layout: _ltFoot.default,
    classNames: ['lt-foot-wrap'],
    table: null,
    sharedOptions: null,

    init: function init() {
      this._super.apply(this, arguments);

      trySet(this, 'sharedOptions.fixedFooter', get(this, 'fixed'));
    }
  });
});