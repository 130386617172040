define('ember-adminlte-theme/components/content-wrapper', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      $ = _ember.default.$;
  exports.default = Component.extend({
    classNames: ["content-wrapper"],
    showBreadcrumbs: false,
    didInsertElement: function didInsertElement() {
      var self = this;

      self.resizeElement();
      $(window).resize(function () {
        self.resizeElement();
      });
    },
    resizeElement: function resizeElement() {
      var headHeight = $('.main-header').outerHeight();
      var footerHeight = $('.main-footer').outerHeight();
      var sidebar_height = $(".sidebar").height();

      var neg = headHeight + footerHeight;
      var window_height = $(window).height();

      if ($("body").hasClass("fixed")) {
        this.setHeight(window_height - footerHeight);
      } else {
        this.setHeight(window_height >= sidebar_height ? window_height - neg : sidebar_height);
      }
    },
    setHeight: function setHeight(height) {
      this.$().css('min-height', height);
    }
  });
});