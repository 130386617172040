define('emberfire-utils/components/firebase-ui-auth', ['exports', 'ember-runloop', 'ember-component', 'ember-service/inject', 'emberfire-utils/templates/components/firebase-ui-auth'], function (exports, _emberRunloop, _emberComponent, _inject, _firebaseUiAuth) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberComponent.default.extend({
    layout: _firebaseUiAuth.default,

    /**
     * @type Ember.Service
     * @readOnly
     */
    firebaseUi: (0, _inject.default)(),

    /**
     * @type {string}
     * @readonly
     */
    elementId: 'firebaseui-auth-container',

    /**
     * Component hook.
     *
     * - Renders the FirebaseUI auth widget
     */
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      (0, _emberRunloop.scheduleOnce)('afterRender', function () {
        _this.get('firebaseUi').startAuthUi(_this.getAttr('uiConfig'));
      });
    },


    /**
     * Component hook.
     *
     * - Reset the FirebaseUI auth widget
     */
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('firebaseUi').resetAuthUi();
    }
  });
});