define('emberfire-utils/services/firebase-ui', ['exports', 'ember-service', 'ember-service/inject', 'firebaseui', 'firebase'], function (exports, _emberService, _inject, _firebaseui, _firebase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberService.default.extend({
    /**
     * @type Ember.Service
     * @readonly
     */
    firebaseApp: (0, _inject.default)(),

    /**
     * @type {FirebaseUi}
     * @readonly
     */
    ui: null,

    /**
     * Service hook
     *
     * - Sets the global firebase variable
     */
    init: function init() {
      this._super.apply(this, arguments);

      // Workaround for when the firebase asset is an AMD module
      window.firebase = _firebase.default;
    },


    /**
     * Starts the FirebaseUI Auth
     *
     * @param {Object} uiConfig
     */
    startAuthUi: function startAuthUi(uiConfig) {
      var auth = this.get('firebaseApp').auth();
      var ui = this.get('ui');

      if (!ui) {
        ui = new _firebaseui.default.auth.AuthUI(auth);
        this.set('ui', ui);
      }

      ui.start('#firebaseui-auth-container', uiConfig);
    },


    /**
     * Resets the FirebaseUI Auth
     */
    resetAuthUi: function resetAuthUi() {
      this.get('ui').reset();
    }
  });
});