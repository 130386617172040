define('ember-css-modules/mixins/controller-mixin', ['exports', 'ember'], function (exports, _ember) {
  var getOwner = _ember['default'].getOwner;
  exports['default'] = _ember['default'].Mixin.create({
    __styles__: _ember['default'].computed(function () {
      // If styles is an explicitly set hash, defer to it. Otherwise, use the resolver.
      if (this.styles && Object.getPrototypeOf(this.styles) === Object.prototype) {
        return this.styles;
      }

      var key = this._debugContainerKey;
      if (!key) {
        return;
      }

      return getOwner(this).resolveRegistration('styles:' + key.split(':')[1]);
    })
  });
});