define('emberfire-utils/serializers/firebase-flex', ['exports', 'ember-string', 'ember-inflector', 'emberfire/serializers/firebase'], function (exports, _emberString, _emberInflector, _firebase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _firebase.default.extend({
    /**
     * @param {DS.Snapshot} snapshot
     * @return {Object} Fanout object for Firebase
     */
    serialize: function serialize(snapshot) {
      var fanout = {};
      var path = this._getPath(snapshot);

      for (var key in snapshot.changedAttributes()) {
        if (key !== '_innerReferencePath') {
          fanout[path + '/' + key] = snapshot.attr(key);
        }
      }

      return fanout;
    },


    /**
     * @param {DS.Snapshot} snapshot
     * @return {string} Firebase path
     * @private
     */
    _getPath: function _getPath(snapshot) {
      if (snapshot.adapterOptions && snapshot.adapterOptions.hasOwnProperty('path')) {
        var pathPrefix = snapshot.adapterOptions.path;

        if (pathPrefix) {
          if (!pathPrefix.startsWith('/')) {
            pathPrefix = '/' + pathPrefix;
          }

          return pathPrefix + '/' + snapshot.id;
        }
      }

      return '/' + (0, _emberString.camelize)((0, _emberInflector.pluralize)(snapshot.modelName)) + '/' + snapshot.id;
    }
  });
});