define('ember-cli-notifications/components/notification-container', ['exports', 'ember', 'ember-cli-notifications/templates/components/notification-container', 'ember-cli-notifications/styles/components/notification-container'], function (exports, _ember, _emberCliNotificationsTemplatesComponentsNotificationContainer, _emberCliNotificationsStylesComponentsNotificationContainer) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var htmlSafe = _ember['default'].String.htmlSafe;
  exports['default'] = Component.extend({
    layout: _emberCliNotificationsTemplatesComponentsNotificationContainer['default'],
    styles: _emberCliNotificationsStylesComponentsNotificationContainer['default'],

    classNameBindings: ['computedPosition'],
    attributeBindings: ['computedStyle:style'],

    zindex: '1060',

    computedPosition: computed('position', function () {
      if (this.get('position')) return this.get('styles.c-notification__container--' + this.get('position'));

      return this.get('styles.c-notification__container--top');
    }),

    computedStyle: computed('zindex', function () {
      return htmlSafe('z-index: ' + this.get('zindex') + ';');
    })
  });
});